<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form data-vv-scope="maerskTaxesForm" method="post">
      <div class="row justify-content-md-center p-3">

        <div class="col-12 col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <!-- Nome -->
                <custom-input
                  v-model="formData.nome"
                  name="formData.nome"
                  :label="this.$i18n.t('form.integrations.tax.nome')"
                  type="text"
                  rootClassName="col-sm-12 mb-4"
                  :max-length="75"
                  v-validate="{ required: true }"
                  :error="submitted = errors.first('formData.nome')">
                </custom-input>

                <!-- Taxa -->
                <tax-field
                  v-model="formData.idtaxaLogistica"
                  :tipoCargaFcl="true"
                  rootClassName="col-12 col-sm-12 form-group mb-4"
                />
              </div>
            </div>
          </div>

        </div>

        <!-- Submit -->
        <div class="col-12">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">Cancelar</button>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
// Components
import CustomInput from '@/components/Forms/CustomInput.vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import TaxField from '@/views/Integrations/Maersk/Taxes/TaxField'
// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import TaxLogisticService from '@/services/TaxLogisticService'

export default {
  name: 'IntegrationMaerskTaxesForm',
  metaInfo () {
    return {
      titleTemplate: this.$route.meta.titleText
    }
  },
  data () {
    return {
      formData: {
        nome: null,
        idtaxaLogistica: null
      },
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      submitted: false
    }
  },
  components: {
    Loading,
    CustomInput,
    TaxField
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isLoading = true

    // If is edit populate values
    let id = this.$route.params.id

    if (id) {
      TaxLogisticService.getTaxa(id).then((res) => {
        _this.formData = res.data.data
        _this.isLoading = false
      })
    } else {
      _this.isLoading = false
    }
  },
  methods: {
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'MaerskTaxesIndex' })
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('maerskTaxesForm').then(valid => {
        if (valid) {
          _this.isLoading = true
          let data = Object.assign({}, _this.formData)

          data.idtaxaLogistica = data.idtaxaLogistica.id

          let id = this.$route.params.id

          if (id) {
            TaxLogisticService.putTaxa(id, data).then(response => {
              _this.$router.push({ name: 'MaerskTaxesIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            TaxLogisticService.postTaxa(data).then(response => {
              _this.$router.push({ name: 'MaerskTaxesIndex' })
            }).finally(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
</style>
